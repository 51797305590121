import { useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { Dropdown } from 'primereact/dropdown';
import "../../assets/css/page-tarifs/Offres.css";
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import logo_virtuel_details from '../../assets/images/img-page-tarifs/logo-pack-details/logo_virtuel_details.png'
import logo_fixe_details from '../../assets/images/img-page-tarifs/logo-pack-details/logo_fixe_details.png'
import logo_pro_details from '../../assets/images/img-page-tarifs/logo-pack-details/logo_pro_details.png'
import logo_pack_fixe from '../../assets/logo/logo_pack_fixe.png'
import { Toast } from 'primereact/toast';
import logo_pack_pro from '../../assets/logo/logo_pack_pro.png'
import logo_pack_virtuel from '../../assets/logo/logo_pack_virtuel.png'
import routes from "../../router/routes";
import Faq from './Faq'
import Tableau from "./Tableau";

export default function Offres({ option, setOption, selectedOfferId, isAnnuel, optionValue, offers, priceIllimites, handleRadioButtonChange }) {
  const location = useLocation();
  const toast = useRef(null);
  const [visibleCondition, setVisibleCondition] = useState(false);
  const inputFilter = /^[a-zA-Z0-9\s]*$/;
  const [value, setValue] = useState('');
  const [selectedNbUser, setSelectedNbUser] = useState(null);
  const [visible, setVisible] = useState(false);
  const urlParams = new URLSearchParams(location.search);
  const [selectedPack, setSelectedPack] = useState("standard");
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [firstname, setFirstName] = useState('');
  const [lastname, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [company, setCompany] = useState('');

  const handlePackClick = (pack) => {
    setSelectedPack(pack);
    if (pack === "fixe") {
      setOption("illimites");
    } else if (pack === "standard") {
      setOption("appelEntrant")
    }
  };

  const showDialog = () => {
    setVisible(true);
  };

  const hideDialog = () => {
    setVisible(false);
  };

  const navigate = useNavigate();
  const handleNavigateToHome = () => {
    if (option === "illimites") {
      if (isAnnuel) urlParams.set("isAnnuel", "true");
      else urlParams.set("isAnnuel", "false");
      window.location.href = `${process.env.REACT_APP_DOMAINE_CENTREX
        }?${urlParams.toString()}`;
    } else {
      urlParams.set("offer", selectedOfferId);
      navigate(`${routes.choixNumero}?${urlParams.toString()}`);
    }
    //TODO: Add the selected offer to the URL
  };

  const nbUser = [
    { name: '1 à 3 salariés', code: '1-3' },
    { name: '4 à 10 salariés', code: '4-10' },
    { name: '11 à 20 salariés', code: '11-20' },
    { name: '21 à 50 salariés', code: '21-50' },
    { name: '51 à 100 salariés', code: '51-100' },
    { name: '100 salariés et plus', code: '100+' }
  ];



  const envoyerRequete = async () => {
    const requestBody = {
      firstname,
      lastname,
      email,
      phone,
      company,
      nbUser: selectedNbUser
    };
    try {
      const request = await fetch("https://env-3387615.jcloud-ver-jpe.ik-server.com/api/v1/email/mail-business-pro", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      const response = await request.json();

      if (response?.success) {
        setIsSent(true);
        toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Votre demande a bien été envoyée', life: 4000 });
      } else {
        toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Erreur lors de l\'envoi. Veuillez réessayer', life: 4000 });
      }
    } catch (error) {
      toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Erreur lors de l\'envoi. Veuillez réessayer', life: 4000 });
    }
  };

  const handleSubmit = () => {
    // Vérifier si tous les champs obligatoires sont remplis
    if (!firstname || !lastname || !email || !phone || !company || !selectedNbUser) {
      alert("Veuillez remplir tous les champs obligatoires.");
      return; // Arrêter l'exécution si un champ obligatoire est vide
    }

    setIsSending(true); // Mettre à jour l'état pour indiquer que l'envoi est en cours
    // Envoyer les informations vers la fonction Offres
    envoyerRequete().then(() => {
      setIsSending(false); // Mettre à jour l'état pour indiquer que l'envoi est terminé
      setVisible(false); // Fermer le dialogue après l'envoi
    });
  };

  const handleDropdownChange = (e) => {
    setSelectedNbUser(e.value.name);
    // Stocker seulement le nom sélectionné
  };




  return (
    <>
      <div className="flex justify-content-center">
        <Toast ref={toast} />
        <div className="flex flex-wrap flex-column container-solutions align-items-center justify-content-center w-auto p-4 pb-7 pl-8 pr-8 mt-4">
          <p className="title-solutions mb-4">Nos solutions</p>
          <div className="flex flex-wrap flex-column gap-6">
            <div className="flex flex-wrap flex-row justify-content-center gap-5 text-white">
              <div
                className={`flex flex-wrap flex-row gap-3 pack pack-standard ${selectedPack === "standard" ? "active" : ""
                  }`}
                onClick={() => handlePackClick("standard")}
              >
                <div className="flex align-items-center">
                  <img className="logo-pack" src={logo_pack_virtuel} alt="" />
                </div>
                <div className="flex flex-column">
                  <p className="pack-title">Pack Standard Virtuel</p>
                  <p className="subtitle">Je démarre</p>
                </div>
              </div>
              <div
                className={`flex flex-row gap-3 pack pack-fixe ${selectedPack === "fixe" ? "active" : ""
                  }`}
                onClick={() => handlePackClick("fixe")}
              >
                <div className="flex align-items-center"><img className="logo-pack" src={logo_pack_fixe} alt="" /></div>
                <div className="flex flex-column">
                  <p className="pack-title">Pack Fixe illimité</p>
                  <p className="subtitle">Je développe</p>
                </div>
              </div>
              <div
                className={`flex flex-row gap-3 pack pack-pro ${selectedPack === "pro" ? "active" : ""
                  }`}
                onClick={() => handlePackClick("pro")}
              >
                <div className="flex align-items-center"><img className="logo-pack" src={logo_pack_pro} alt="" /></div>
                <div className="flex flex-column">
                  <p className="pack-title">Pack Business Pro</p>
                  <p className="subtitle">Je performe</p>
                </div>
              </div>
            </div>
            {selectedPack === "standard" && (
              <div
                id="optionAppelEntrant"
                name="Group"
                onChange={handleRadioButtonChange}
                checked={option === "appelEntrant"}
                value="appelEntrant"
                className="flex flex-row justify-content-between align-items-center pack-details p-5 pack-details-standard">
                <div className="flex">
                  <div className="flex flex-column gap-3">
                    <div className="flex flex-row gap-3">
                      <img src={logo_virtuel_details} alt="" />
                      <div className="">
                        <p className="pack-title">Pack Standard Virtuel</p>
                        <p className="subtitle">Je démarre</p>
                      </div>
                    </div>
                    <div className="flex f-bold">
                      <p className="info-offre">Activez un Standard Téléphonique Virtuel en 2 minutes sans aucune connaissance technique et recevez vos appels sur vos téléphones fixes et mobiles que vous avez déjà</p>
                    </div>
                    <div className="flex flex-column gap-0 f-bold">
                      A partir de<br />
                      <p className="pack-price">
                        {Number(
                          offers[optionValue].filter(
                            (offer) => offer.id === selectedOfferId
                          )[0].prix
                        ).toFixed(2)}
                        €<small style={{ fontSize: '15px' }}>{isAnnuel ? "HT/an" : "HT/mois"}</small></p>
                    </div>
                    <div className="flex justify-content-center">
                      <button className="trialButton w-full text-center" onClick={handleNavigateToHome}>Essai gratuit</button>
                    </div>
                  </div>
                </div>
                <div className="flex flex-column gap-4 list-details-offre">
                  <div className="flex flex-row align-items-center gap-5 f-bold">
                    <i className="pi pi-check" style={{ color: '#00BD9D', fontSize: '1.8rem' }}></i>
                    <p>Standard Téléphonique tout compris avec renvoi d’appel vers vos lignes fixes et mobiles</p>
                  </div>
                  <div className="flex flex-row align-items-center gap-5 f-bold">
                    <i className="pi pi-check" style={{ color: '#00BD9D', fontSize: '1.8rem' }}></i>
                    <p>Un numéro local (01, 02), national (09) pour votre entreprise ou conservez le votre</p>
                  </div>
                  <div className="flex flex-row align-items-center gap-5 f-bold">
                    <i className="pi pi-check" style={{ color: '#00BD9D', fontSize: '1.8rem' }}></i>
                    <p>Activation 100% en ligne instantanée</p>
                  </div>
                  <div className="flex flex-row align-items-center gap-5 f-bold">
                    <i className="pi pi-check" style={{ color: '#00BD9D', fontSize: '1.8rem' }}></i>
                    <p>Sans engagement</p>
                  </div>
                </div>
              </div>
            )}
            {selectedPack === "fixe" && (
              <div
                onChange={handleRadioButtonChange}
                id="optionIllimite"
                name="Group"
                checked={option === "illimites"}
                value="illimites"
                className="flex flex-row align-items-center justify-content-between p-5 pack-details pack-details-fixe">
                <div className="flex">
                  <div className="flex flex-column gap-3">
                    <div className="flex flex-row gap-3">
                      <img src={logo_fixe_details} alt="" />
                      <div className="">
                        <p className="pack-title">Pack Fixe illlimité</p>
                        <p className="subtitle">Je développe</p>
                      </div>
                    </div>
                    <div className="flex f-bold">
                      <p className="info-offre">Adoptez la téléphonie fixe virtuelle conçue pour répondre aux besoins de votre entreprise</p>
                    </div>
                    <div className="flex flex-column gap-0 f-bold">
                      A partir de<br />
                      <div className="flex flex-row align-items-baseline">
                        <p className="pack-price m-0">
                          {priceIllimites.toFixed(2)}
                          €</p><small style={{ fontSize: '14px' }}><b>{isAnnuel ? "HT/utilisateur/an" : "HT/utilisateur/mois"}</b></small>
                      </div>
                    </div>
                    <div className="flex justify-content-center">
                      <p style={{ backgroundColor: '#1757FF' }} className="trialButton w-full text-center" onClick={handleNavigateToHome}>Essai gratuit</p>
                    </div>
                    <p onClick={showDialog} className="w-full cursor-pointer link-condition">Conditions liées aux appels sortants</p>
                    <Dialog header="Conditions liées au appels sortants" visible={visible} style={{ width: '50vw' }} onHide={hideDialog}>
                      <p className="w-full">Appels sortants inclus limité à 100 correspondants différents maximum par mois par utilisateur et 1h maximum par appel (hors coût du services de n° spéciaux,  destinations internationales non incluses et renvoi d’appel vers numéros externes). Au delà, facturation hors forfait au tarif de 0,15€/min HT</p>
                    </Dialog>
                  </div>
                </div>
                <div className="flex flex-column gap-4 f-bold list-details-offre">
                  <div className="flex flex-row align-items-center gap-5">
                    <i className="pi pi-check" style={{ color: '#1757FF', fontSize: '1.8rem' }}></i>
                    <p>Standard Téléphonique Virtuel tout inclus</p>
                  </div>
                  <div className="flex flex-row align-items-center gap-5">
                    <i className="pi pi-check" style={{ color: '#1757FF', fontSize: '1.8rem' }}></i>
                    <p>Un numéro local (01, 02), national (09) pour votre entreprise ou conservez le votre</p>
                  </div>
                  <div className="flex flex-row align-items-center gap-5">
                    <i className="pi pi-check" style={{ color: '#1757FF', fontSize: '1.8rem' }}></i>
                    <p>Appels inclus vers Fixes et Mobiles <span className="link-condition cursor-pointer" onClick={showDialog}>*voir conditions</span>
                    </p>
                    <Dialog header="Conditions liées au appels sortants" visible={visible} style={{ width: '50vw' }} onHide={hideDialog}>
                      <p>Appels sortants inclus limité à 100 correspondants différents maximum par mois par utilisateur et 1h maximum par appel (hors coût du services de n° spéciaux,  destinations internationales non incluses et renvoi d’appel vers numéros externes). Au delà, facturation hors forfait au tarif de 0,15€/min HT</p>
                    </Dialog>
                  </div>
                  <div className="flex flex-row align-items-center gap-5">
                    <i className="pi pi-check" style={{ color: '#1757FF', fontSize: '1.8rem' }}></i>
                    <p>Application mobile et ordinateur</p>
                  </div>
                  <div className="flex flex-row align-items-center gap-5">
                    <i className="pi pi-check" style={{ color: '#1757FF', fontSize: '1.8rem' }}></i>
                    <p>Activation 100% en ligne instantanée</p>
                  </div>
                  <div className="flex flex-row align-items-center gap-5">
                    <i className="pi pi-check" style={{ color: '#1757FF', fontSize: '1.8rem' }}></i>
                    <p>Sans engagement</p>
                  </div>
                </div>
              </div>
            )}
            {selectedPack === "pro" && (
              <div className="flex flex-row justify-content-between p-5 pack-details pack-details-pro">
                <div className="flex">
                  <div className="flex flex-column gap-3">
                    <div className="flex flex-row gap-3">
                      <img src={logo_pro_details} alt="" />
                      <div className="">
                        <p className="pack-title">Pack Business Pro</p>
                        <p className="subtitle">Je performe</p>
                      </div>
                    </div>
                    <div className="flex f-bold">
                      <p className="info-offre">Une Box Internet Pro tout compris qui sécurise votre activité et s’adapte à vos besoins</p>
                    </div>
                    <div className="flex flex-column">
                      <p className="f-bold">À partir de</p>
                      <p className="pack-price">79,90€<small style={{ fontSize: '12px' }}>/mois HT</small></p>
                    </div>
                    <div>
                      <Button style={{ backgroundColor: 'black', borderRadius: '40px', fontWeight: '10px' }} className="w-full" label="Demander un devis" onClick={() => setVisible(true)} />
                      <Dialog header={
                        <>
                          <div className="flex flex-column align-items-center gap-2">
                            <h2>Pack Business Pro</h2>
                            <h4 className="">La solution complète de téléphonie et de connectivité qui s’adapte à vos besoins.</h4>
                          </div>
                        </>
                      } visible={visible} draggable={false} onHide={() => { if (!visible) return; setVisible(false); }}>
                        <div className="text-center mb-4">
                          <p>Vous souhaitez souscrire au Pack Business Pro ? <b>Demandez un devis ci-dessous</b></p>
                        </div>

                        <div className="flex flex-column gap-3">
                          <InputText
                            className="p-2"
                            keyfilter={inputFilter}
                            placeholder="Nom"
                            required
                            value={lastname}
                            onChange={(e) => setLastName(e.target.value)}
                          />
                          <InputText
                            className="p-2"
                            keyfilter={inputFilter}
                            placeholder="Prénom"
                            required
                            value={firstname}
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                          <InputText
                            className="p-2"
                            keyfilter="email"
                            required
                            placeholder="E-mail"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          <InputMask
                            className="p-2"
                            mask="99 99 99 99 99"
                            placeholder="Téléphone"
                            keyfilter={/^[0-9]+$/}
                            minLength="10"
                            maxLength="10"
                            required
                            pattern="[0][0-9]{9}"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                          />
                          <InputText
                            required
                            className="p-2"
                            placeholder="Société"
                            value={company}
                            onChange={(e) => setCompany(e.target.value)}
                          />
                          <Dropdown
                            required
                            value={nbUser.find(option => option.name === selectedNbUser) || null}
                            onChange={handleDropdownChange}
                            options={nbUser}
                            optionLabel="name"
                            placeholder="Nombre de salariés"
                            className="w-full p-2"
                          />
                          <div className="button-form">
                            <Button
                              type="submit"
                              className="w-full"
                              label={isSending || isSent ? (isSent ? "ENVOYÉ" : "ENVOI EN COURS") : "Confirmer"}
                              onClick={handleSubmit} // Appeler handleSubmit au clic sur le bouton
                              style={{ backgroundColor: 'black', fontWeight: "0" }}
                            />
                            <p style={{ fontSize: '12px' }} className="mt-3 text-center">COLOR TELECOM recueille ces informations pour gérer votre demande de devis, vous adresser ses offres commerciales et marketing, et suivre les performances de ses campagnes publicitaires.</p>
                          </div>
                        </div>
                      </Dialog>
                    </div>
                  </div>
                </div>
                <div className="flex flex-column gap-4 f-bold list-details-offre">
                  <div className="flex flex-row align-items-center gap-5">
                    <i className="pi pi-check" style={{ color: '#0D081C', fontSize: '1.8rem' }}></i>
                    <p>Fibre Pro sécurisée très haut débit jusqu’à 1 Gb/s</p>
                  </div>
                  <div className="flex flex-row align-items-center gap-5">
                    <i className="pi pi-check" style={{ color: '#0D081C', fontSize: '1.8rem' }}></i>
                    <p>Standard Téléphonique Pro</p>
                  </div>
                  <div className="flex flex-row align-items-center gap-5">
                    <i className="pi pi-check" style={{ color: '#0D081C', fontSize: '1.8rem' }}></i>
                    <p>Appels inclus vers Fixes et Mobiles *voir conditions
                    </p>
                  </div>
                  <div className="flex flex-row align-items-center gap-5">
                    <i className="pi pi-check" style={{ color: '#0D081C', fontSize: '1.8rem' }}></i>
                    <p>Application mobile et ordinateur</p>
                  </div>
                  <div className="flex flex-row align-items-center gap-5">
                    <i className="pi pi-check" style={{ color: '#0D081C', fontSize: '1.8rem' }}></i>
                    <p>Assistance et supervision proactive</p>
                  </div>
                </div>
              </div>

            )}
          </div>

        </div>
      </div>

      <div className="mt-8">
        <p className="flex justify-content-center mb-6" style={{ fontSize: '40px', fontWeight: '700' }}>Comparez nos solutions</p>
        <Tableau />
      </div>

      <Faq selectedPack={selectedPack} />

    </>
  );
}

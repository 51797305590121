import React, { useState, useEffect } from "react";
import "../../assets/css/page-tarifs/Header.css";
import Tableau from "./Tableau";
import { useMediaQuery } from "react-responsive";
import Offres from "./Offres";
import { Dropdown } from 'primereact/dropdown';
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import logo from "../../assets/logo/logo.png";

const OfferHours = ({ offer, current, handleButtonClick }) => {
  if (offer.nombre_heure === "Illimité") return null;
  const nom = offer.nom.split("/")[0];
  const isActive = offer.id === current;
  return (
    <div className="column">
      <button
        className={`button-hours ${isActive ? "active" : ""}`}
        onClick={() => handleButtonClick(offer)}
      >
        <span className={isActive ? "active-text" : "inactive-text"}>
          {nom}
        </span>
      </button>
    </div>
  );
};

export default function Header({ offers }) {
  const optionsValues = ["forfaitM", "forfaitA"];
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [optionValue, setOptionValue] = useState(optionsValues[0]);
  const options = ["Mensuel", "Annuel"];
  const [option, setOption] = useState("");
  const [value, setValue] = useState(options[0]);
  const [priceIllimites, setPriceIllimites] = useState(1);
  const isMobile = useMediaQuery({ maxWidth: 767 }); // Ajout de la requête média pour les écrans mobiles
  const [selectedOfferId, setSeletedOfferId] = useState(
    offers[optionValue][0].id
  );

  const handleButtonClick = (offer) => {
    setSeletedOfferId(offer.id);
  };

  const handleRadioButtonChange = (e) => {
    setOption(e.target.value);
  };

  const products = [
    { name: 'Pack Standard Virtuel', url: 'https://www.standardenligne.fr/pack-standard-virtuel/' },
    { name: 'Pack Fixe Illimités', url: 'https://www.standardenligne.fr/pack-fixe-illimite/' },
    { name: 'Pack Business Pro', url: 'https://www.standardenligne.fr/pack-business-pro' }
  ];

  const handleDropdownChange = (e) => {
    setSelectedProduct(e.value);
    window.location.href = e.value.url; // Rediriger vers la nouvelle URL dans le même onglet
  };

  useEffect(() => {
    setPriceIllimites(
      Number(
        offers[optionValue]?.filter(
          (item) => item?.nombre_heure === "Illimité"
        )[0]?.prix
      )
    );
  }, [value, offers, optionValue]);

  const dropdownProduct = {
    border: 'none',
    color: '#333',
    outline: 'none',
    boxShadow: '0 2px 5px #fff',

  };


  return (
    <>
      <div className="header">
        <div className="flex flex-column">
          <div className="flex flex-row align-items-center justify-content-around header-tarifs">
            <div className="flex flex-row align-items-center gap-5">
              <div className="flex">
                <img src={logo} className="" alt="" />
              </div>
              <Dropdown
                style={dropdownProduct}
                value={selectedProduct}
                onChange={handleDropdownChange}
                options={products}
                optionLabel="name"
                placeholder="Produit"
                className="p-2"
              />
              <b><a href="/"><p style={{ color: 'black' }}>Tarifs</p></a></b>
              <div style={{ minWidth: '150px' }}> {/* Ajustement de la largeur minimale */}
                <p className="w-full"><i className="pi pi-phone"> </i> 01 87 200 200</p>
              </div>
            </div>
            <div className="glimmer-orange"></div>
            <div className="flex flex-row align-items-center gap-6">
              <a href="https://www.monstandardenligne.com/"><p style={{ color: 'black' }}>Espace client</p></a>
              <a href="/"><p className="trialButton">Essai gratuit</p></a>
            </div>
          </div>
          <div className="flex align-items-center justify-content-center">
            <p className="flex band-color align-items-center justify-content-center"><i className="pi pi-star-fill icon-star mr-2"></i>Choisissez un numéro facile à retenir pour votre entreprise: <b className="mr-1 ml-1">Numéro Argent et OR OFFERT</b> pour toute nouvelle commande</p>
          </div>
        </div>
        {/* <div className="flex justify-content-center align-items-center p-3 mt-3">
          <p className="flex justify-content-center align-items-center band">Choisissez un numéro facile à retenir pour votre entreprise: Numéro <b className="mr-1 ml-1">Argent et OR OFFERT</b> pour toute nouvelle commande (au lieu de <s className="mr-1 ml-1">100€</s> à l’achat)</p>
        </div> */}
        <p className="text-center mt-8 title-tarifs">Les solutions de communications dédiées<br /> à votre entreprise</p>
        <h1 className="title-header">
          {isMobile
            ? "Quel plan vous convient le mieux ?"
            : ""}
        </h1>
        <div className="">
          <div className="flex justify-content-center align-items-center">
            <div className="flex select-button-background align-items-center">
              <div class=" flex tooltip-container align-items-center">
              </div>
              <div className="flex select-button justify-content-center align-items-center pl-3 pr-3 mt-4">
                <button
                  className={`monthly-annual-button cursor-pointer mensuel-button ${value === "Mensuel" ? "active" : "text-grayed"
                    }`}
                  onClick={() => {
                    setValue("Mensuel");
                    setOptionValue(optionsValues[0]);
                    handleButtonClick(offers["forfaitM"][0]);
                  }}
                >
                  Mensuel
                </button>
                <button
                  className={`monthly-annual-button annual-button cursor-pointer mensuel-button annuel-tooltip ${value === "Annuel" ? "active" : "text-grayed"
                    }`}
                  onClick={() => {
                    setValue("Annuel");
                    setOptionValue(optionsValues[1]);
                    handleButtonClick(offers["forfaitA"][0]);
                  }}
                >
                  Annuel <small className="ml-1">(2 mois offerts)</small>
                </button>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <Offres
          handleRadioButtonChange={handleButtonClick}
          priceIllimites={priceIllimites}
          offers={offers}
          option={option}
          setOption={setOption}
          optionValue={optionValue}
          selectedOfferId={selectedOfferId}
          isAnnuel={"Annuel" === value} />
        <div className="glimmer-blue"></div>
      </div>

      {/* <div className="card flex justify-content-center">
          <div className="select-button-background">
            <div class="tooltip-container">
            </div>
            <div className="select-button">
              <button
                className={`monthly-annual-button mensuel-button ${value === "Mensuel" ? "active" : ""
                  }`}
                onClick={() => {
                  setValue("Mensuel");
                  setOptionValue(optionsValues[0]);
                  handleButtonClick(offers["forfaitM"][0]);
                }}
              >
                Mensuel
              </button>
              <button
                className={`monthly-annual-button mensuel-button annuel-tooltip ${value === "Annuel" ? "active" : ""
                  }`}
                onClick={() => {
                  setValue("Annuel");
                  setOptionValue(optionsValues[1]);
                  handleButtonClick(offers["forfaitA"][0]);
                }}
              >
                Annuel 2 mois offert
              </button>
            </div>
          </div>

        </div>
        <div className="grid-container-options">
          <div className="container-overlay">
            <div className="overlay">
              <h2 className="options-title" style={{ marginBottom: "1rem" }}>
                <div className="flex align-items-center flex-wrap justify-content-center">
                  <FormControl component="fieldset">
                    <FormControlLabel
                      control={
                        <Radio
                          className="white-color"
                          id="optionAppelEntrant"
                          name="Group"
                          value="appelEntrant"
                          onChange={handleRadioButtonChange}
                          checked={option === "appelEntrant"}
                        />
                      }
                    />
                  </FormControl>
                  <label
                    htmlFor="optionAppelEntrant"
                    className=""
                    style={{ marginRight: "3rem" }}
                  >
                    Appel entrant
                  </label>
                </div>
              </h2>
              <div style={{ height: "10rem" }} className="content-overlay">
                <h1
                  style={{
                    marginBottom: "1rem",
                    position: "relative",
                    top: "10px",
                  }}
                  className="price"
                >
                  {Number(
                    offers[optionValue].filter(
                      (offer) => offer.id === selectedOfferId
                    )[0].prix
                  ).toFixed(2)}
                  €<small className="ht">ht</small>
                </h1>
                <div className="grid-container-hours">
                  {offers[optionValue]?.map((offer) => (
                    <OfferHours
                      key={offer.id}
                      offer={offer}
                      current={selectedOfferId}
                      handleButtonClick={handleButtonClick}
                    />
                  ))}
                </div>
                <p className="recep-month-2">
                  {value === "Mensuel" ? "par mois" : "par an"} de réception
                </p>
              </div>
            </div>
          </div>
          <div className="container-overlay" style={{ marginLeft: "5rem" }}>
            <div className="overlay red-background">
              <h2 className="options-title" style={{ marginBottom: "1rem" }}>
                <div className="flex align-items-center flex-wrap justify-content-center">
                  <FormControl component="fieldset">
                    <FormControlLabel
                      control={
                        <Radio
                          id="optionIllimite"
                          name="Group"
                          value="illimites"
                          onChange={handleRadioButtonChange}
                          checked={option === "illimites"}
                          style={{ color: "white" }}
                        />
                      }
                    />
                  </FormControl>
                  <label
                    htmlFor="optionIllimite"
                    style={{ marginRight: "2rem" }}
                  >
                    Appel entrant
                    <br /> & sortant illimités
                  </label>
                </div>
              </h2>
              <div style={{ height: "8.5rem" }} className="content-overlay">
                <div className="content-overlay content-overlay-illimited-option">
                  <p style={{ marginTop: "1rem" }}>A partir de </p>
                  <h1 className="price price-illimited-options">
                    {priceIllimites.toFixed(2)}€<small className="ht">ht</small>
                  </h1>
                  <p
                    className="recep-month-2"
                    style={{
                      marginTop: "0",
                      width: "120%",
                      position: "relative",
                      right: "1rem",
                    }}
                  >
                    {value === "Mensuel" ? "par mois" : "par an"} /utilisateur
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Offres
        option={option}
        selectedOfferId={selectedOfferId}
        isAnnuel={"Annuel" === value}
      />
      <Tableau
        offers={offers[optionValue]}
        selectedOfferId={selectedOfferId}
        option={value}
        optionValue={optionValue}
        setSeletedOfferId={setSeletedOfferId}
      /> */}

    </>
  );
}

import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import logo_pack_fixe from '../../assets/logo/logo_pack_fixe.png'
import logo_pack_pro from '../../assets/logo/logo_pack_pro.png'
import logo_pack_virtuel from '../../assets/logo/logo_pack_virtuel.png'
import "primeicons/primeicons.css";
import { Tooltip } from "primereact/tooltip";
import "../../assets/css/page-tarifs/Tableau.css";
import routes from "../../router/routes";

const TableauChecklist = ({
  option,
  setSeletedOfferId,
  offers,
  selectedOfferId,
}) => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const [options, setOptions] = useState({
    Section1: { title: "Numéros & utilisateurs" },
    //
    Option1: {
      title: "1 numéro fixe par entreprise - 12 pays inclus",
      tooltip:
        "1 numéro fixe par entreprise dans les pays suivants: Autriche, Belgique, Danemark, France, Allemagne, Irlande, Italie, Pays-Bas, Portugal, Espagne, Suède, Suisse, Royaume-Uni)",
      colonne1: true,
      colonne2: true,
      colonne3: true,
    },
    Option0: {
      title: "1 numéro fixe par utilisateur",
      tooltip:
        "",
      colonne1: true,
      colonne2: true,
      colonne3: true,
    },
    Option2: {
      title: "Numéros internationaux",
      tooltip:
        "Numéros disponibles dans les pays suivants: Albanie, Angola, Argentine, Australie, Bahrein, Bangladesh, Barbades, Belize, Benin, Bolivie, Bosnie Herzégovine, Brésil, Bulgarie, Burkina Faso, Burma, Cambodge, Cameroun, Canada, Iles Cayman, Chili, Chine, Colombie, Costa Rica, Croatie, Chypre, République Tchèque, Équateur, Egypte, Salvador, Estonie, Finlande, Géorgie, Ghana, Grèce, Grenade, Guatemala, Guinée, Honduras, Hong Kong, Hongrie, Islande, Inde, Indonésie, Irlande, Israel, Italie, Cote d’ivoire, Jamaïque, Japon, Jordanie, Kazakhstan, Kenya, Kosovo, Koweit, Lituanie, Lettonie, Luxembourg, Macédoine, Malaisie, Malte, Mexique, Moldavie, Montenegro, Maroc, Nicaragua, Nigeria, Norvège, Pakistan, Panama, Paraguay, Pérou, Philippines, Pologne, Portugal, Porto Rico, Qatar, Roumanie, Arabie Saoudite, Serbie, Seychelles, Slovaquie, Slovénie, Afrique du Sud, Corée du Sud, Soudan, Taiwan, Thaïlande, Trinidad et Tobago, Tunisie, Ouganda, Ukraine, Emirats Arabes Unis, Etats-Unis, Uruguay, Venezuela, Vietnam, Zimbabwe",
      colonne1: false,
      colonne2: false,
      colonne3: false
    },
    Option00: {
      title: "Utilisateurs illimités",
      tooltip:
        "",
      colonne1: true,
      colonne2: false,
      colonne3: false,
    },
    //
    Section2: { title: "Appels" },
    Option300: {
      title: 'Appels entrants avec redirection d’appels vers des numéros externes',
      tooltip:
        "Recevez vos appels entrants sur les fixes et mobiles de votre entreprise (Ligne fixe de box, ligne mobile)",
      colonne1: true,
      colonne2: false,
      colonne3: false
    },

    Option3: {
      title: "Appels entrants illimités",
      tooltip:
        "Recevez les appels de votre standard téléphonique en illimité sur votre application mobile et ordinateur",
      colonne1: false,
      colonne2: true,
      colonne3: true
    },
    Option4: {
      title: "Appels sortants vers Fixes et Mobiles en France Métropolitaine",
      tooltip:
        "Appelez vers les Fixes et Mobiles en France Métropolitaine et Corse. Appels sortants inclus limité à 100 correspondants différents maximum par mois par utilisateur et 1h maximum par appel (hors coût du services de n° spéciaux,  destinations internationales non incluses et renvoi d’appel vers numéros externes). Au delà, facturation hors forfait au tarif de 0,15€/min HT",
      colonne1: false,
      colonne2: true,
      colonne3: true
    },
    Option5: {
      title: "Redirections d'appels vers destinations internationales",
      tooltip:
        "Renvoyez vos appels vers les Fixes et Mobiles des destinations internationales suivantes: Australie, Autriche, Belgique, Brésil, Bulgarie, Canada, Chili, Chypre, Colombie, Croatie, Danemark, Espagne, Estonie, États-Unis, Finlande, Grèce, Guadeloupe, Guyane, Hongrie, Irlande, Israël, Italie, Lettonie, Lichtenstein, Lituanie, Luxembourg, Malaisie, Malte, Martinique, Mayotte, Mexique, Pays-Bas, Pologne, Porto Rico, Portugal, République tchèque, Réunion, Roumanie, Royaume-Uni, Slovaquie, Slovénie, Suède, Suisse, Thaïlande",
      colonne1: true,
      colonne2: false,
      colonne3: false
    },
    Option6: {
      title: "Appels sortants vers destinations internationales",
      tooltip:
        "Appelez vers les destinations internationales suivantes: Andorre (Fixe), Argentine (Fixe), Australie (Fixe et Mobile), Autriche (Fixe), Bangladesh (Fixe et Mobile), Belgique (Fixe et Mobile), Bermudes (Fixe et Mobile), Brésil (Fixe et Mobile), Bulgarie (Fixe et Mobile), Canada (Fixe et Mobile), Chili (Fixe), Colombie (Fixe et Mobile), Costa Rica (Fixe), Chypre du Sud (Fixe), République Tchèque (Fixe et Mobile), Danemark (Fixe et Mobile), République Dominicaine (Fixe), Finlande (Fixe et Mobile), Guyane Française (Fixe et Mobile), Allemagne, (Fixe et Mobile), Gibraltar (Fixe), Grèce (Fixe), Guadeloupe (Fixe et Mobile), Guam (Fixe et Mobile), Hong-Kong (Fixe et Mobile), Hongrie (Fixe et Mobile), Islande (Fixe et Mobile), Inde (Fixe et Mobile), Indonésie (Fixe et Mobile), Irlande (Fixe et Mobile), Israël (Fixe et Mobile), Italie (Fixe et Mobile), Japon (Fixe), Corée du Sud (Fixe et Mobile), Luxembourg (Fixe et Mobile), Malaisie (Fixe et Mobile), Malte (Fixe et Mobile), Martinique (Fixe et Mobile), Mayotte (Fixe et Mobile), Mexique (Fixe et Mobile), Mongolie (Fixe et Mobile), Pays-Bas (Fixe et Mobile), Nouvelle Zélande (Fixe et Mobile), Norvège (Fixe et Mobile), Panama (Fixe), Paraguay (Fixe), Pérou (Fixe et Mobile), Pologne (Fixe et Mobile), Portugal (Fixe et Mobile), Porto-Rico (Fixe et Mobile), Réunion (Fixe et Mobile), Roumanie (Fixe et Mobile), Russie (Fixe), Saint Marin (Fixe), Slovaquie (Fixe et Mobile), Espagne (Fixe), Suède (Fixe et Mobile), Suisse (Fixe), Taiwan (Fixe), Turquie (Fixe), Royaume-Uni (Fixe et Mobile), États-Unis (Fixe et Mobile), Hawaii (Fixe et Mobile), Venezuela (Fixe et Mobile), Iles Vierges Américaines (Fixe et Mobile) ",
      colonne1: false,
      colonne2: true,
      colonne3: true
    },
    Option7: {
      title: "Notification d'appels",
      tooltip: "Recevez une notification vocale lorsque vous recevez un appel",
      colonne1: true,
      colonne2: true,
      colonne3: true
    },
    Option8: {
      title: "Application mobile et ordinateur",
      tooltip:
        "Émettez et recevez des appels depuis votre ordinateur et mobile",
      colonne1: false,
      colonne2: true,
      colonne3: true
    },
    Option9: {
      title: "Réception de fax par e-mail",
      tooltip: "Recevez des fax par e-mail",

    },
    //
    Section3: { title: "SMS" },

    Option10: {
      title: "Envoi de SMS",
      tooltip: "Envoyez des SMS publicitaires à vos prospects et clients en utilisant le nom de votre entreprise",
      colonne1: false,
      colonne2: false,
    },


    Section11: { title: "Standard Téléphonique" },

    Option12: {
      title: "Pré-décroché",
      tooltip:
        "Créez des annonces vocales personnalisées en haute définition grâce à notre studio en ligne",
      colonne1: true,
      colonne2: true,
      colonne3: true
    },
    Option13: {
      title: "Musique d'attente",
      tooltip:
        "Personnalisez l'attente de vos appelants grâce à notre bibliotheque de 10 musiques libre de droit",
      colonne1: true,
      colonne2: true,
      colonne3: true
    },
    Option14: {
      title: "Gestion des horaires",
      tooltip:
        "Gérez vos appels clients facilement en fonction de vos horaires d'ouverture et de fermeture",
      colonne1: true,
      colonne2: true,
      colonne3: true
    },
    Option15: {
      title: "Redirections d'appels",
      tooltip:
        "Redirigez les appels vers les bons interlocuteurs grâce à différentes règles: Renvoi d'appel en cascade, aléatoire, simultané (Un numéro externe est un numéro non attribué par Standard en ligne)",
      colonne1: true,
      colonne2: true,
      colonne3: true
    },
    Option16: {
      title: "Menu Multichoix",
      tooltip:
        "Redirigez les appels vers le bon service ou le bon interlocuteur grâce à un menu tapez 1, tapez 2. (Ex: Tapez 1 pour joindre le service commercial, tapez 2 pour joindre le service technique)",
      colonne1: true,
      colonne2: true,
      colonne3: true
    },
    Option17: {
      title: "File d'attente",
      tooltip:
        "Votre ligne ne sonnera plus jamais occupée: Le Standard Téléphonique distribue les appels en fonction du nombre de correspondants disponibles pour les décrocher. Si vous êtes en ligne, l'appel est mis en attente et vous est présenté lorsque vous avez raccroché",
      colonne1: true,
      colonne2: true,
      colonne3: true
    },
    //
    Section4: { title: "Suivi Statistique" },

    Option19: {
      title: "Journal d'appel interactif",
      tooltip:
        "Annotez chaque appel client ou prospect et filtre vos appels en fonction de plusieurs critères: Appels reçus, manqués, messagerie",
      colonne1: true,
      colonne2: true,
      colonne3: true
    },
    Option20: {
      title: "Enregistrement d'appels",
      tooltip: "Enregistrez les appels entrants de votre Standard Téléphonique",
      colonne1: false,
      colonne2: false,
    },
    Option21: {
      title: "Click to call",
      tooltip:
        "Cliquez sur un numéro pour l'appel sans avoir besoin de le saisir sur votre application mobile et PC",
      colonne1: false,
      colonne2: true,
      colonne3: true
    },
    Option22: {
      title: "Choix du numéro de téléphone sortant",
      tooltip:
        "Sélectionnez le numéro de téléphone que vous souhaitez faire apparaitre lors d'un appel sortant",
      colonne1: false,
      colonne2: true,
      colonne3: true
    },
    Option23: {
      title: "Changement d'appareil en cours de communication",
      tooltip:
        "Permutez un appel en cours sur un autre dispositif sans que l'appelant ne s'en aperçoive",
      colonne1: false,
      colonne2: true,
      colonne3: true
    },
    Option24: {
      title: "Messages vocaux par email",
      tooltip: "Recevez vos messages vocaux directement par e-mail",
      colonne1: true,
      colonne2: true,
      colonne3: true
    },
    Option25: {
      title: "Statistiques",
      tooltip:
        "Obtenez des statistiques détaillées sur les appels entrants, transferts d'appels",
      colonne1: true,
      colonne2: true,
      colonne3: true
    },
    //
    Section5: { title: "Outils collaboratifs" },

    Option26: {
      title: "Répertoire de contacts partagés entre utilisateurs",
      tooltip:
        "Intégrez manuellement ou via un fichier excel les contacts existants dans votre application",
      colonne1: true,
      colonne2: true,
      colonne3: true
    },
    Option27: {
      title: 'Mode "Ne pas déranger"',
      tooltip:
        "Activez la fonctionnalité ne pas déranger pour ne pas faire sonner votre application",
      colonne1: true,
      colonne2: true,
      colonne3: true
    },
    Option28: {
      title: "Sonneries personnalisées",
      tooltip:
        "Sélectionnez une sonnerie personnalisée lorsque vous recevez un appel entrant",
      colonne1: false,
      colonne2: true,
      colonne3: true
    },
    Option29: {
      title: "Messagerie collaborative",
      tooltip:
        "Échangez des messages et fichiers avec vos collaborateurs via la messagerie interne sur votre application",
      colonne1: false,
      colonne2: true,
      colonne3: true
    },
    Option30: {
      title: "Conférence téléphonique",
      tooltip:
        "Créez une conférence téléphonique jusqu'à 3 personnes depuis votre application sans frais supplémentaires",
      colonne1: false,
      colonne2: true,
      colonne3: true
    },
    //
    Section6: { title: "Intégrations" },
    Option31: {
      title: "E-mail",
      tooltip: "Recevez des notificatins d'appels directement par e-mail",
      colonne1: true,
      colonne2: true,
      colonne3: true
    },
    Option32: {
      title: "Intégrations CRM natives",
      tooltip:
        "Plus de 10 CRM intégrés nativement à votre licence PRO:  Salesforce, Hubspot, Zendesk, Pipedrive, Zoho, MS Dynamics, Odoo, Freshdesk, Efficy, Freshsales, Lime, etc",
      colonne1: false,
      colonne2: true,
      colonne3: true
    },
    //
    Section7: { title: "Support client" },
    Option33: {
      title: "Portabilité de votre numéro historique",
      tooltip:
        "Conservez votre numéro actuel sans coupure ni interruption de service",
      colonne1: true,
      colonne2: true,
      colonne3: true
    },
    Option34: {
      title: "Plateforme d'onboarding",
      tooltip:
        "Configurez facilement et rapidement votre premier standard téléphonique et activez le en quelques clics",
      colonne1: true,
      colonne2: true,
      colonne3: true
    },
    Option35: {
      title: "Support par e-mail et téléphone",
      tooltip: "Support par e-mail et téléphone",
      colonne1: true,
      colonne2: true,
      colonne3: true
    },
    Option36: {
      title: "Responsable de compte dédié",
      tooltip:
        "Un chargé de compte vous accompagne durant votre abonnement pour vous assister à l'utilisation de la solution et lorsque vous rencontrez un incident (Valable uniquement pour une souscription en paiement annuel)",
      colonne1: false,
      colonne2: false,
    },

    // Ajoutez autant d'options et de sections que nécessaire
  });

  const handleNavigateToHome = () => {
    urlParams.set("offer", selectedOfferId);
    navigate(`${routes.choixNumero}?${urlParams.toString()}`);
  };

  const handleToggleOption = (option, colonne, event) => {
    const clickedCell = event.target.closest(".no-clickable-content");
    if (clickedCell) {
      return;
    }

    setOptions((prevOptions) => ({
      ...prevOptions,
      [option]: {
        ...prevOptions[option],
        [colonne]: !prevOptions[option][colonne],
      },
    }));
  };

  const calculateAppelEntrantSortant = () => {
    const offer = offers.filter((item) => item.nombre_heure === "Illimité");
    return Number(offer[0]?.prix).toFixed(2); // Prix mensuel ou annuel pour le forfait illimité
  };

  const calculateAppelEntrant = () => {
    const offer = offers.filter((item) => item.id === selectedOfferId);
    return Number(offer[0]?.prix).toFixed(2);
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <table className="tableau-checklist" style={{ marginLeft: "20px", marginRight: "20px" }}>
        <thead>
          <tr>
            <th style={{ backgroundColor: "white", opacity: "0", border: "none" }}></th>
            <th className="sticky-title pack-virtuel-table">
              <div className="flex flex-row align-items-center text-left gap-3">
                <img className="logo-pack" src={logo_pack_virtuel} alt="" />
                <div style={{ color: 'white', fontWeight: '0' }} className="flex flex-column w-full">
                  <p className="pack-table">Pack Standard Virtuel</p>
                  <p className="pack-table-subtitle">Je démarre</p>
                </div>
              </div>
            </th>
            <th className="sticky-title pack-fixe-table">
              <div className="flex flex-row align-items-center text-left gap-3">
                <img className="logo-pack" src={logo_pack_fixe} alt="" />
                <div style={{ color: 'white', fontWeight: '0' }} className="flex flex-column w-full">
                  <p className="pack-table">Pack Fixe Illimité</p>
                  <p className="pack-table-subtitle">Je développe</p>
                </div>
              </div>
            </th>
            <th className="sticky-title pack-pro-table">
              <div className="flex flex-row align-items-center text-left gap-3">
                <img className="logo-pack" src={logo_pack_pro} alt="" />
                <div style={{ color: 'white', fontWeight: '0' }} className="flex flex-column w-full">
                  <p className="pack-table">Pack Business Pro</p>
                  <p className="pack-table-subtitle">Je performe</p>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(options).map((option) => {
            if (option.startsWith("Section")) {
              return (
                <tr key={option} className="section">
                  <td colSpan="4">{options[option].title}</td>
                </tr>
              );
            } else {
              return (
                <tr key={option}>
                  <td className="options-background">
                    {options[option].title || option}
                    <Tooltip target=".ici" tooltipOptions={{ pt: { text: { className: "tooltip" } } }} />
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className="ici custom-target-icon pi pi-info-circle"
                      id={`tooltip-${option}`}
                      data-pr-tooltip={options[option].tooltip || ""}
                      data-pr-position="right"
                      data-pr-at="right+10 top"
                      data-pr-my="left center-2"
                      style={{ fontSize: "15px", marginLeft: "5px", color: "#00BD9D" }}
                    />
                  </td>
                  <td
                    className={`no-clickable-content ${options[option].colonne1 ? "compris" : ""}`}
                    onClick={(event) => handleToggleOption(option, "colonne1", event)}
                  >
                    {option === "Option10" ? (
                      <span
                        className="option-text"
                        style={{ fontWeight: "600", color: 'rgb(0, 189, 157)' }}
                      >
                        Option
                      </span>
                    ) : options[option].colonne1 ? (
                      ""
                    ) : (
                      ""
                    )}
                    {option === "Option2" ? (
                      <span
                        className="option-text"
                        style={{ fontWeight: "600", color: 'rgb(0, 189, 157)' }}
                      >
                        Option
                      </span>
                    ) : options[option].colonne1 ? (
                      ""
                    ) : (
                      ""
                    )}
                    {option === "Option20" ? (
                      <span
                        className="option-text"
                        style={{ fontWeight: "600", color: 'rgb(0, 189, 157)' }}
                      >
                        Option
                      </span>
                    ) : options[option].colonne1 ? (
                      ""
                    ) : (
                      ""
                    )}
                    {option === "Option36" ? (
                      <span
                        className="option-text"
                        style={{ fontWeight: "600", color: 'rgb(0, 189, 157)' }}
                      >
                        À partir de 20 numéros
                      </span>
                    ) : options[option].colonne1 ? (
                      ""
                    ) : (
                      ""
                    )}
                    {option === "Option9" ? (
                      <span
                        className="option-text"
                        style={{ fontWeight: "600", color: 'rgb(0, 189, 157)' }}
                      >
                        Option
                      </span>
                    ) : options[option].colonne1 ? (
                      ""
                    ) : (
                      ""
                    )}
                    {options[option].colonne1 && <FontAwesomeIcon style={{ width: '10px', backgroundColor: '#00BD9D' }} icon={faCheck} />}
                  </td>
                  <td
                    className={`no-clickable-content ${options[option].colonne2 ? "compris" : ""}`}
                    onClick={(event) => handleToggleOption(option, "colonne2", event)}
                  >
                    {option === "Option10" ? (
                      <span
                        className="option-text"
                        style={{ fontWeight: "600", color: 'rgb(23, 87, 255)' }}
                      >
                        Option
                      </span>
                    ) : options[option].colonne2 ? (
                      ""
                    ) : (
                      ""
                    )}
                    {option === "Option2" ? (
                      <span
                        className="option-text"
                        style={{ fontWeight: "600", color: 'rgb(23, 87, 255)' }}
                      >
                        Option
                      </span>
                    ) : options[option].colonne2 ? (
                      ""
                    ) : (
                      ""
                    )}
                    {option === "Option20" ? (
                      <span
                        className="option-text"
                        style={{ fontWeight: "600", color: 'rgb(23, 87, 255)' }}
                      >
                        Option
                      </span>
                    ) : options[option].colonne2 ? (
                      ""
                    ) : (
                      ""
                    )}
                    {option === "Option36" ? (
                      <span className="option-text"
                        style={{ fontWeight: "600", color: 'rgb(23, 87, 255)' }}>
                        À partir de 20 licences
                      </span>
                    ) : options[option].colonne2 ? (
                      ""
                    ) : (
                      ""
                    )}
                    {option === "Option9" ? (
                      <span className="option-text"
                        style={{ fontWeight: "600", color: 'rgb(23, 87, 255)' }}>
                        Option
                      </span>
                    ) : options[option].colonne2 ? (
                      ""
                    ) : (
                      ""
                    )}
                    {options[option].colonne2 && <FontAwesomeIcon style={{ width: '10px', backgroundColor: '#1757FF' }} icon={faCheck} />}
                  </td>
                  <td
                    className={`no-clickable-content ${options[option].colonne3 ? "compris" : ""}`}
                    onClick={(event) => handleToggleOption(option, "colonne3", event)}
                  >
                    {options[option].colonne3 && <FontAwesomeIcon style={{ width: '10px', backgroundColor: '#0D081C' }} icon={faCheck} />}
                    {option === "Option36" ? (
                      <span className="option-text"
                        style={{ fontWeight: "600", color: 'black' }}>
                        Inclus
                      </span>
                    ) : options[option].colonne3 ? (
                      ""
                    ) : (
                      ""
                    )}
                    {option === "Option2" ? (
                      <span className="option-text"
                        style={{ fontWeight: "600", color: 'black' }}>
                        Option
                      </span>
                    ) : options[option].colonne3 ? (
                      ""
                    ) : (
                      ""
                    )}
                    {option === "Option9" ? (
                      <span className="option-text"
                        style={{ fontWeight: "600", color: 'black' }}>
                        Option
                      </span>
                    ) : options[option].colonne3 ? (
                      ""
                    ) : (
                      ""
                    )}
                    {option === "Option20" ? (
                      <span className="option-text"
                        style={{ fontWeight: "600", color: 'black' }}>
                        Option
                      </span>
                    ) : options[option].colonne3 ? (
                      ""
                    ) : (
                      ""
                    )}
                    {option === "Option10" ? (
                      <span className="option-text"
                        style={{ fontWeight: "600", color: 'black' }}>
                        Option
                      </span>
                    ) : options[option].colonne3 ? (
                      ""
                    ) : (
                      ""
                    )}
                  </td>

                </tr>
              );
            }
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TableauChecklist;
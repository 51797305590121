import React, { useEffect, useState } from "react";
import { useLocalStorage } from "primereact/hooks";
import { pays, typeNumber, zones } from "../../static";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { numberChooseSchema } from "../../schema/numberChooseSchema";
import ControlledInput from "../inputs/ControlledInput";
import InputContainer from "../containers/InputContainer";
import Numbers from "./Numbers";
import EditNumber from "./EditNumber";
import '../../assets/css/NumberChoose.css'
import Steps from "./Steps";
import {
  countryOptionTemplate,
  forfaitSelectedTemplate,
  forfaitValueTemplate,
  groupedItemTemplate,
  optionTemplate,
  selectedCountryTemplate,
  valueTemplate,
} from "../inputs/Templates";
import { onNumerChoose } from "../../querys/inscription";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import routes from "../../router/routes";
import MainButton from "./MainButton";
import useOffers from "../../hooks/useOffers";
import { offersToDropdownGroup } from "../../utils/commun";
import { Tooltip } from "primereact/tooltip";
import { RadioButton } from 'primereact/radiobutton';

const NumberChoose = () => {
  const [selectedForfaitType, setSelectedForfaitType] = useState("Annuel");
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const selectedOffer = Number(searchParams.get("offer")) ?? undefined;
  const { data: offers, isLoading } = useOffers(
    "selectOffers",
    offersToDropdownGroup
  );
  const [, setChoixNumero] = useLocalStorage({}, "choixNumero");
  const navigate = useNavigate();
  const methods = useForm({
    values: {
      pays: "France",
      forfait: selectedOffer,
      numeroType: "or",
    },
    resolver: zodResolver(numberChooseSchema),
  });

  const paysValue = useWatch({
    control: methods.control,
    name: "pays",
  });
  const zoneValue = useWatch({
    control: methods.control,
    name: "zone",
  });
  const numeroTypeValue = useWatch({
    control: methods.control,
    name: "numeroType",
  });
  const selectedNumberValue = useWatch({
    control: methods.control,
    name: "number",
  });
  const selectedForfaitValue = useWatch({
    control: methods.control,
    name: "forfait",
  });

  useEffect(() => {
    if (selectedOffer && offers) {
      const forfait = offers.find((offer) =>
        offer.items.some((item) => item.value === selectedOffer)
      );
      if (forfait) {
        setSelectedForfaitType(forfait.label);
        methods.setValue("forfait", selectedOffer);
      }
    }
  }, [selectedOffer, offers, methods]);

  const onSubmit = (data) => {
    const cleanData = onNumerChoose(data);
    setChoixNumero(JSON.stringify(cleanData));
    navigate(routes.informationEntreprise + location.search);
  };

  const handleForfaitChange = (forfaitValue) => {
    methods.setValue("forfait", forfaitValue);
  };

  const handleForfaitTypeChange = (forfaitType) => {
    setSelectedForfaitType(forfaitType);
    methods.setValue("forfait", undefined);
  };

  if (isLoading) return null;

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className="px-4 lg:pl-8 pb-8"
      >
        <Steps step={1} maxStep={3} />
        <h5 className="flex w-12">
          <Tooltip
            target=".conserver-num"
            content={`Si vous souhaitez utiliser un numéro déjà existant avec Standard en ligne, c’est facile et sans coupure !\nChoisissez un numéro de votre choix, et une fois votre commande validée, envoyez un email à support@standardenligne.fr
            Nous effectuerons la portabilité de votre numéro historique sans coupure ni interruption de service afin que vous puissiez utiliser Standard en ligne avec le même numéro de téléphone.`}
            position="top"
            event="focus"
          />
          <a
            href="#"
            className="conserver-num text-black-alpha-90 underline font-medium text-sm sm:text-sx py-2 "
          >
            Vous souhaitez conserver votre numéro historique ?
          </a>
        </h5>
        <InputContainer
          show={true}
          label="Dans quel pays souhaitez-vous obtenir un numéro de téléphone ?"
        >
          <ControlledInput name="pays" control={methods.control}>
            <Dropdown
              className="w-full h-3rem align-items-center border-round border-1 text-black-alpha-90 font-bold bg-white pl-3 md:p-4"
              placeholder="Choisissez le pays"
              options={pays}
              optionLabel="label"
              optionValue="value"
              valueTemplate={selectedCountryTemplate}
              itemTemplate={countryOptionTemplate}
            />
          </ControlledInput>
        </InputContainer>
        {paysValue !== "France" ? (
          <p style={{ backgroundColor: '#16C498', width: '605px' }} className="mt-6 text-white p-3 border-round">
            Pour obtenir un numéro dans ce pays, contactez-nous au 01 87 200 200
          </p>
        ) : null}
        <InputContainer
          show={paysValue === "France"}
          label="Dans quelle zone ?"
        >
          <ControlledInput name="zone" control={methods.control}>
            <Dropdown
              className="w-full h-3rem align-items-center border-round border-1 text-black-alpha-90 font-bold bg-white pl-3 md:p-4"
              placeholder="Choisissez la zone"
              options={zones}
              optionLabel="label"
              optionValue="value"
              valueTemplate={valueTemplate}
            />
          </ControlledInput>
        </InputContainer>

        <InputContainer show={zoneValue} label="Choisissez votre forfait">
        <div className="flex flex-row flex-wrap gap-3 cursor-pointer justify-content-center mt-6">
            <div
              className={`field-radiobutton cursor-pointer radio-forfait ${selectedForfaitType === "Mensuel" ? "active-background" : ""}`}
              onClick={() => handleForfaitTypeChange("Mensuel")}
            >
              <RadioButton
                className="m-0 custom-radio"
                inputId="mensuel"
                name="forfaitType"
                value="Mensuel"
                checked={selectedForfaitType === "Mensuel"}
                onChange={() => handleForfaitTypeChange("Mensuel")}
              />
             <label className="cursor-pointer" htmlFor="mensuel">Mensuel</label>
            </div>
            <div
              className={`field-radiobutton cursor-pointer radio-forfait ${selectedForfaitType === "Annuel" ? "active-background" : ""}`}
              onClick={() => handleForfaitTypeChange("Annuel")}
            >
              <RadioButton
                className="m-0 custom-radio"
                inputId="annuel"
                name="forfaitType"
                value="Annuel"
                checked={selectedForfaitType === "Annuel"}
                onChange={() => handleForfaitTypeChange("Annuel")}
              />
              <label className="cursor-pointer" htmlFor="annuel">Annuel</label>
            </div>
          </div>
          <div className="mb-6">
            {selectedForfaitType === "Annuel" && (
              <div className="flex flex-row flex-wrap gap-3 justify-content-center align-items-center">
                {offers.find((offer) => offer.label === "Annuel").items.map((item) => (
                  <div
                    key={item.value}
                    className={`radio-forfait cursor-pointer div-area ${selectedForfaitValue === item.value ? "active-background" : ""}`}
                    onClick={() => handleForfaitChange(item.value)}
                  >
                    <RadioButton
                      className="m-0 custom-radio cursor-pointer"
                      inputId={item.value}
                      name="forfait"
                      value={item.value}
                      checked={selectedForfaitValue === item.value}
                      onChange={() => handleForfaitChange(item.value)}
                    />
                    <div className="flex flex-column justify-content-center align-items-center cursor-pointer">
                      <label
                        htmlFor={item.value}
                        className={item.nombre_heure === "Illimité"
                          ? "secondary-bg p-2 border-round light-primary-text cursor-pointer"
                          : "cursor-pointer"}
                      >
                        {item.nombre_heure === "Illimité" ? "Illimité" : item.label}
                      </label>
                      <span className="redirect-sentence cursor-pointer">de redirection d’appels</span>
                      <b className="forfait-prix cursor-pointer">{item.prix}€</b>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {selectedForfaitType === "Mensuel" && (
             <div className="flex flex-row gap-3 cursor-pointer justify-content-center align-items-center">
                {offers.find((offer) => offer.label === "Mensuel").items.map((item) => (
                  <div
                    key={item.value}
                    className={`radio-forfait cursor-pointer ${selectedForfaitValue === item.value ? "active-background" : ""}`}
                    onClick={() => handleForfaitChange(item.value)}
                  >
                    <RadioButton
                     className="m-0 custom-radio cursor-pointer"
                      inputId={item.value}
                      name="forfait"
                      value={item.value}
                      checked={selectedForfaitValue === item.value}
                      onChange={() => handleForfaitChange(item.value)}
                    />
                    <div className="flex flex-column justify-content-center align-items-center cursor-pointer">
                      <label htmlFor={item.value} className="cursor-pointer">{item.nombre_heure}h/mois</label>
                      <span className="redirect-sentence cursor-pointer">de redirection d’appels</span>
                      <b className="forfait-prix cursor-pointer">{item.prix}€</b>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </InputContainer>
        <InputContainer
          show={selectedForfaitValue && zoneValue}
          label="Choisissez votre numéro"
        >
          <ControlledInput name="numeroType" control={methods.control}>
            <Dropdown
              className="w-full h-3rem align-items-center border-round border-1 text-black-alpha-90 font-bold bg-white pl-3 md:p-4"
              placeholder="Choisissez votre numéro"
              options={typeNumber}
              optionLabel="label"
              optionValue="value"
              valueTemplate={optionTemplate}
              itemTemplate={optionTemplate}
            />
          </ControlledInput>
        </InputContainer>

        {numeroTypeValue !== "editNumber" ? (
          <InputContainer show={zoneValue}>
            <ControlledInput name="number" control={methods.control}>
              <Numbers
                selectedNumber={selectedNumberValue}
                numberZone={zoneValue}
                numberType={numeroTypeValue}
              />
            </ControlledInput>
          </InputContainer>
        ) : (
          <InputContainer show={zoneValue}>
            <ControlledInput name="number" control={methods.control}>
              <EditNumber numberZone={zoneValue} />
            </ControlledInput>
          </InputContainer>
        )}

        {selectedNumberValue ? (
          <div className="flex relative w-12 lg:w-8 align-items-center justify-content-center lg:justify-content-end mt-4 mb-3">
            <MainButton />
          </div>
        ) : null}
      </form>
    </FormProvider>
  );
};

export default NumberChoose;

import { useStripe, useElements } from "@stripe/react-stripe-js";
import CardSection from "./CardSection";
import MainButton from "../inscription/MainButton";
import { toast } from "react-toastify";
import Secure3D from "../../assets/images/3d-secure.png";

import {
  stripeAttachPaymentMethod,
  createStripeSubscription,
  stripeConfirmSetupIntent,
  stripeCreatePaymentIntent, createStripeCustomer, stripeSearchCustomers
} from "../../services/stripe";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import routes from "../../router/routes";
import axios from "axios";
import { useEffect } from "react";
import sendRequest from "../../services/API";
import API from "../../services/API";

export default function CheckoutForm({ commande, prixForfait, clientSecret, client, numero }) {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  const methods = useForm();

  const handleSubmit = async (event) => {
    if (!stripe || !elements) {
      return null;
    }

    try {
      let customerResponse;
    
      // Step 1: Check if customer already exists (based on email or other identifier)
      const existingCustomerResponse = async (client) => {
        try {
          console.log("Searching for existing customer with email:", client.email);
    
          // Use the stripeSearchCustomers function to check if the customer exists
          const response = await stripeSearchCustomers({
            email: client.email,
          });
    
          console.log("Customer search response:", response);
    
          if (response?.success && response.customer) {
            console.log("Customer found:", response.customer);
            return response.customer; // Return the found customer object
          } else {
            console.log("No customer found.");
            return null; // No customer found
          }
        } catch (error) {
          console.error("Error fetching customer:", error);
          throw new Error("Error fetching customer from backend");
        }
      };
    
      // Search for existing customer
      const existingCustomer = await existingCustomerResponse(client);
    
      // If customer exists, use the existing customer
      if (existingCustomer) {
        customerResponse = existingCustomer;
        console.log("Existing customer found:", customerResponse);
      } else {
        // Step 2: If no existing customer, create a new one
        console.log("Creating new customer...");
        customerResponse = await createStripeCustomer({ client, commande });
    
        if (!customerResponse?.id) {
          throw new Error("Failed to create Stripe customer");
        }
        console.log("New customer created:", customerResponse);
      }
    
      // Step 3: Confirm Payment
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: window.location.origin + routes.successCheckout,
        },
        redirect: "if_required",
      });
    
      if (error) {
        toast.error(error.message, {
          position: "bottom-right",
        });
        return null;
      }
    
      // Step 4: Handle Payment Intent status
      const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);
    
      switch (paymentIntent.status) {
        case "requires_capture":
          try {
            // Attach Payment Method to Customer
            const attachResponse = await stripeAttachPaymentMethod({ commande });
            if (!attachResponse.success) return false;
    
            // Create Subscription
            const createSubscriptionResponse = await createStripeSubscription({ commande });
            if (createSubscriptionResponse?.success === true) {
              navigate(`${routes.successCheckout}?commande=${commande}`);
            }
            break;
          } catch (error) {
            toast.error(error?.message, { position: "bottom-right" });
            break;
          }
    
        case "processing":
          // Handle processing state
          console.log("Payment is processing...");
          break;
    
        case "requires_payment_method":
          toast.error(paymentIntent.last_setup_error?.message, {
            position: "bottom-right",
          });
          break;
    
        default:
          break;
      }
    } catch (error) {
      toast.error(error.message, { position: "bottom-right" });
    }    
  };

  const ButtonConversion = () => {
    if (window.gtag) {
      window.gtag('event', 'conversion', {
        'send_to': 'AW-1000263363/22r7CKyKpPkYEMOd-9wD',
        'value': prixForfait,
        'currency': 'EUR',
        'transaction_id': commande
      });
    }
  };


  return (
      <form onSubmit={methods.handleSubmit(handleSubmit)} className="mt-4">
        <div className="border-1 border-300	p-3 my-2">
          <CardSection />
        </div>
        <div className="w-full  flex align-items-center my-3">
        <span className="font-medium text-md font-italic	">
          Protocole sécurisé par le système 3D Secure{" "}
        </span>
          <span className="mx-4 flex align-items-center">
          <img src={Secure3D} width={25} height={28} alt="3D secure payment" />
        </span>
        </div>
        <div className="flex relative w-full align-items-center justify-content-center lg:justify-content-end mt-6 mb-3">
          <MainButton
              label="Configurer mon standard"
              disabled={methods.formState.isSubmitting || !stripe}
          onClick={ButtonConversion}/>
        </div>
      </form>
  );
}

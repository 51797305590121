import { useRef, useState } from 'react';
import { Panel } from 'primereact/panel';
import { Divider } from 'primereact/divider';
import { Dialog } from 'primereact/dialog';
import { Fieldset } from 'primereact/fieldset';
import '../../assets/css/page-tarifs/faq.css';

export default function Faq({ selectedPack }) {
    const [visibleCondition, setVisibleCondition] = useState(false);
    const [visible, setVisible] = useState(false);

    const ref = useRef(null);
    const [isOpen, setIsOpen] = useState(false);



    let lineStyle;
    switch (selectedPack) {
        case 'fixe':
            lineStyle = { backgroundColor: '#B7CBFF' };
            break;
        case 'standard':
            lineStyle = { backgroundColor: '#B0EBE1' };
            break;
        case 'pro':
            lineStyle = { backgroundColor: '#B4B2B9' };
            break;
        default:
            lineStyle = {}; // Style par défaut si aucun pack n'est sélectionné
    }

    let buttonStyle;
    switch (selectedPack) {
        case 'fixe':
            buttonStyle = { backgroundColor: '#1757FF', minWidth: '220px' }; // Ajouter minWidth
            break;
        case 'standard':
            buttonStyle = { backgroundColor: '#00BD9D', minWidth: '220px' }; // Ajouter minWidth
            break;
        case 'pro':
            buttonStyle = { backgroundColor: '#0D081C', minWidth: '220px' }; // Ajouter minWidth
            break;
        default:
            buttonStyle = { minWidth: '220px' }; // Style par défaut avec minWidth
    }


    let panelClass;
    switch (selectedPack) {
        case 'fixe':
            panelClass = 'faq-panel-fixe';
            break;
        case 'standard':
            panelClass = 'faq-panel-standard';
            break;
        case 'pro':
            panelClass = 'faq-panel-pro';
            break;
        default:
            panelClass = ''; // Classe par défaut si aucun pack n'est sélectionné
    }

    const toggleFieldset = () => {
        setIsOpen(!isOpen);
    };
    const showDialog = () => {
        setVisible(true);
    };

    const hideDialog = () => {
        setVisible(false);
    };


    return (
        <>
            <div className='flex flex-column text-center faq-content'>
                <p className='mb-5 mt-6' style={{ fontSize: '50px', fontWeight: '700' }}> Foire aux questions</p>
                <div>
                    <div className="flex flex-column mr-8 ml-8" >
                        <div>
                            <Divider />
                            <Panel className={`panel ${panelClass}`} collapsed ref={ref} header="Qu’est-ce Standard en ligne ?" toggleable>
                                <p className="m-0 mb-5">
                                    Standard en ligne est la solution idéale pour les petites et moyennes entreprises qui souhaitent bénéficier d’un Standard Téléphonique facile à utiliser pour pouvoir gérer efficacement ses appels entrants.
                                    Il suffit de quelques clics pour choisir un numéro de téléphone, configurer son Standard Téléphonique (Pré-décroché, renvoi d’appels), et commencer recevoir ses premiers appels clients depuis son téléphone mobile, application mobile ou ordinateur, sans aucune connaissance technique.
                                    Standard en ligne vous permet de suivre précisément les appels reçus sur votre standard grâce au journal d’appel interactif, l’intégration CRM , les enregistrements d’appels ainsi que les notifications par e-mail d’appels
                                </p>
                            </Panel>
                            <Divider />
                        </div>
                        <div>
                            <Panel className={`panel ${panelClass}`} collapsed ref={ref} header="Comment utiliser Standard en ligne ?" toggleable>
                                <p className="m-0 mb-5">
                                    Pour utiliser Standard en ligne, rien de plus simple: Il vous suffit d’avoir une ligne fixe ou mobile pour recevoir vos appels sur vos téléphone existants, ou d’un smartphone et un ordinateur pour émettre et recevoir vos appels téléphoniques.<p> <span className="link-condition cursor-pointer" onClick={showDialog}>*voir conditions</span>
                                    </p>
                                    <Dialog header="Conditions liées au appels sortants" visible={visible} style={{ width: '50vw' }} onHide={hideDialog}>
                                        <p>Appels sortants inclus limité à 100 correspondants différents maximum par mois par utilisateur et 1h maximum par appel (hors coût du services de n° spéciaux,  destinations internationales non incluses et renvoi d’appel vers numéros externes). Au delà, facturation hors forfait au tarif de 0,15€/min HT</p>
                                    </Dialog>
                                </p>
                            </Panel>
                            <Divider />
                        </div>
                        <div>
                            <Panel className={`panel ${panelClass}`} collapsed ref={ref} header="Combien coûte Standard en ligne ?" toggleable>
                                <p className="m-0 mb-5">
                                    Standard en ligne est une solution 100% sans engagement et sans frais de mise en service. Standard en ligne propose plusieurs forfaits adaptés à votre besoin et à votre consommation
                                </p>
                            </Panel>
                            <Divider />
                        </div>
                        <div>
                            <Panel className={`panel ${panelClass}`} collapsed ref={ref} header="Puis-je arrêter l’abonnement quand je le souhaite ?" toggleable>
                                <p className="m-0 mb-5">
                                    Standard en ligne est un opérateur 100% sans engagement: Vous pouvez ainsi résilier facilement à tout moment sans préavis en envoyant un simple e-mail.
                                </p>
                            </Panel>
                            <Divider />
                        </div>
                        <div>
                            <Panel className={`panel ${panelClass}`} collapsed ref={ref} header="Est-ce que les appels sont inclus dans les offres ?" toggleable>
                                <p className="m-0 mb-5">
                                    Oui, des forfaits d’appels sont inclus dans chacune de nos offres. Pour les offres de Standard Virtuel, vous disposez de 1h, 6h, 10h ou 30h de redirection d’appels vers vos lignes fixes et mobiles existantes en France Métropolitaine, Corse et +50 destinations internationales.
                                    Pour les offres VOIP, vous bénéficiez d’appels entrants illimités et d’appels sortants inclus  <span className='link-condition cursor-pointer' onClick={showDialog}>(voir conditions) </span>vers les Fixes et Mobiles en France Métropolitaine et Corse avec le forfait Start, et d’appels entrants illimités et sortants inclus <span className='link-condition cursor-pointer' onClick={showDialog}>(voir conditions) </span> vers +50 destinations internationales Fixes et Mobiles avec le forfait Pro.
                                </p>
                                <Dialog header="Conditions liées au appels sortants" visible={visible} style={{ width: '50vw' }} onHide={hideDialog}>
                                    <p>Appels sortants inclus limité à 100 correspondants différents maximum par mois par utilisateur et 1h maximum par appel (hors coût du services de n° spéciaux,  destinations internationales non incluses et renvoi d’appel vers numéros externes). Au delà, facturation hors forfait au tarif de 0,15€/min HT</p>
                                </Dialog>
                            </Panel>
                            <Divider />
                        </div>
                    </div>
                </div>
            </div>
            {selectedPack === 'pro' && (
                <div className="flex flex-column align-items-center mt-5 voir-condition">
                    <legend onClick={toggleFieldset} style={{ cursor: 'pointer' }}>
                        <p className='p-2' style={{ backgroundColor: '#DDD', borderRadius: '18px' }}>Voir conditions</p>
                    </legend>
                    <fieldset className={`custom-fieldset ${isOpen ? 'open' : 'closed'}`}>
                        <div style={{ width: '1000px' }}>
                            <div className="voir-conditions">
                                <p>
                                    <ul style={{ listStyle: 'none' }}>
                                        <li className='mb-2'><small className='f-10'><b>(1)</b> </small>Fibre Pro Très Haut Débit : débit descendant jusqu’à 1 Gbps et débit montant jusqu’à 800 Mbps (fibre optique basée sur la technologie FTTH).</li>
                                        <li className='mb-2'><small className='f-10'><b>(2)</b> </small>Appels illimités depuis la France métropolitaine vers +70 destinations internationales Fixes et Mobiles. (hors n° courts et n° spéciaux)</li>
                                        <li className='mb-2'><small className='f-10'><b>(3)</b> </small>Plus de 36 millions de prises éligibles sur les réseaux multi-opérateurs partenaires de Standard en ligne</li>
                                        <li className='mt-5 mb-2'>Toutes les offres, garanties et services énoncés sur cette page sont soumis à conditions et sous réserve d’éligibilité. Offre soumise à un engagement de 36 mois minimum</li>
                                        <li className='mb-2'>Frais de mise en service mensualisés sur 36 mois.</li>
                                        <li className='mb-2'>Frais de traitement de résiliation : accès ADSL, SDSL et Fibre Pro 59€ HT, Fibre dédiée 300€ HT</li>
                                        <li>Location Routeur Internet non inclus: 5€/mois HT</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </fieldset>
                </div>
            )}
            <div style={lineStyle} className="flex flex-row text-left justify-content-between align-items-center mt-8 p-3">
                <div className="flex flex-column c-white">
                    <p style={{ fontSize: '25px' }} className='ml-6 mb-0 mb-2'>Vous souhaitez souscrire par téléphone ?
                    </p>
                    <p style={{ width: '85%' }} className='ml-6'>Notre équipe vous aide à choisir la solution la plus adaptée à votre entreprise. Souscrivez en quelques minutes par téléphone et activez votre solution en quelques clics</p>
                </div>
                <button
                    className="trialButton btn mr-6"
                    style={buttonStyle}
                >
                    Appeler au 01 87 200 200
                </button>
            </div>

        </>
    )
}
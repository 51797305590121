const API_URL = process.env.REACT_APP_DOMAINE;
const API_Routes = {
  fetchNumbersByZone: `${API_URL}/numeros/all`,
  fetchAllOffers: `${API_URL}/forfaits/all/display`,
  createClient: `${API_URL}/clients/create`,
  isEmailAvailable: `${API_URL}/clients/is-email-available`,
  isEntityNameAvailable: `${API_URL}/clients/is-entity-name-available`,
  createCommande: `${API_URL}/commandes/create`,
  getCommandeByConfigToken: `${API_URL}/commandes/config`,
  createAbonnement: `${API_URL}/abonnements/create`,
  createCheckoutSessionEssai: `${API_URL}/create-checkout-session-essai`,
  createStripeCustomer: `${API_URL}/stripe/create-customer`,
  createStripeSubscription: `${API_URL}/stripe/create-subscription`,
  cancelStripeSubscription: `${API_URL}/stripe/cancel-subscription`,
  stripeAttachPaymentMethod: `${API_URL}/stripe/attach-method`,
  stripeCreatePaymentIntent: `${API_URL}/stripe/create-payment-intent`,
  stripeConfirmPaymentIntent: `${API_URL}/stripe/confirm-payment-intent`,
  stripeConfirmSetupIntent: `${API_URL}/stripe/confirm-setup-intent`,
  stripeCreateSetupIntent: `${API_URL}/stripe/create-setup-intent`,
  stripeSearchCustomers: `${API_URL}/stripe/search-customers`,

  email: `${API_URL}/email`,
};

export default API_Routes;
